body {
  margin: 0;
  overflow: hidden;
}

header {
  position: absolute;
  top: 10px;
  left: 10px;

  p {
    margin: 0;
  }

  button {
    margin: 10px 5px 0 0;
    border-radius: 30px;
    border: none;
    color: #fff;
    padding: 8px 12px;
    cursor: pointer;

    &[data="taken"] { background-color: #005eff; }
    &[data="free"] { background-color: #ff2a00; }
    &[data="electric"] { background-color: #1ad051; }
    &[data="capacity"] { background-color: #ffb700; }
  }
}

footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: right;

  p {
    margin: 0;
    a {
      color: currentColor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
